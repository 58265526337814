import { ActionIcon, Box, Group, Loader, Tabs, Title } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { IconPlus, IconX } from '@tabler/icons-react';
import { useRouter } from 'next/router';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';

import classes from './project-menu.module.css';

import Drawer from '@/core/components/molecules/drawer/drawer';
import LiveSearchBar from '@/core/components/organisms/no-projects/live-search-bar/live-search-bar';
import { Paths } from '@/core/constants/routes.constants';
import { Z_INDEX } from '@/core/constants/z-index.constants';
import { useCurrentUser } from '@/core/hooks/query-hooks/use-current-user/use-current-user';
import { useMyRuns } from '@/core/hooks/query-hooks/use-my-runs/use-my-runs';
import {
  useGetBookmarkedProjects,
  useProjects
} from '@/core/hooks/query-hooks/use-projects/use-projects';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useGenericPermissions } from '@/core/hooks/use-permissions/use-generic-permissions';
import useProjectType from '@/core/hooks/use-project-type/use-project-type';
import { useGlobalStore } from '@/core/stores/global-store/global.store';
import { IProjectNameFilter } from '@/core/types/project-filters.types';
import {
  Project,
  PROJECT_TYPE,
  ProjectType
} from '@/core/types/projects.types';

import ProjectMenuAccordion from './project-menu-accordion/project-menu-accordion';
import ProjectMenuEmptyState from './project-menu-empty-state/project-menu-empty-state';
import ProjectMenuFilters from './project-menu-filters/project-menu-filters';
import { LinkButton } from '../../atoms/link-button/link-button';

const TABS = {
  MY_RUNS: '0',
  ALL_RUNS: '1'
};

const ProjectMenu = () => {
  // Router
  const router = useRouter();

  // Path Params
  const { projectId } = usePathParameters();

  // Refs
  const searchRef = useRef<HTMLInputElement>(null);

  // Global state
  const isProjectMenuOpen = useGlobalStore((state) => state.isProjectMenuOpen);
  const filterProps = useGlobalStore((state) => state.filterProps);
  const sortProps = useGlobalStore((state) => state.sortProps);
  const { setIsProjectMenuOpen, setFilterProps, setSortProps } = useGlobalStore(
    (state) => state.actions
  );
  const debouncedSetFilterProps = useDebouncedCallback(setFilterProps, 500);

  // Local state
  const [searchTerm, setSearchTerm] = useState('');

  // Default to All Runs as active tab
  const [activeTab, setActiveTab] = useState(TABS.ALL_RUNS);
  const [expandedRows, setExpandedRows] = useState([projectId?.toString()]);

  // Utility Hooks
  const projectType = useProjectType(
    isProjectMenuOpen || ('unknown' as ProjectType)
  );

  // Query Hooks
  const { data: currentUser, isPending: isCurrentUserLoading } =
    useCurrentUser();

  const {
    projects: NlpProjects,
    isPending: isNlpProjectsLoading,
    fetchNextPage: fetchNextNlpPage,
    hasNextPage: hasNlpNextPage,
    isFetching: isFetchingNextNlpPage
  } = useProjects(PROJECT_TYPE.TRAINING_INFERENCE);
  const {
    projects: evaluateProjects,
    isPending: isEvaluateProjectsLoading,
    fetchNextPage: fetchNextEvaluatePage,
    hasNextPage: hasEvaluateNextPage,
    isFetching: isFetchingNextEvaluatePage
  } = useProjects(PROJECT_TYPE.PROMPT_EVALUATION);
  const {
    projects: observeProjects,
    isPending: isObserveProjectsLoading,
    fetchNextPage: fetchNextObservePage,
    hasNextPage: hasObserveNextPage,
    isFetching: isFetchingNextObservePage
  } = useProjects(PROJECT_TYPE.OBSERVE);

  const {
    projects: NlpBookmarkedProjects,
    isPending: isNlpBookmarkedProjectsLoading,
    fetchNextPage: fetchNextBookmarkedNlpPage,
    hasNextPage: hasNlpNextBookmarkedPage,
    isFetching: isFetchingNextBookmarkedNlpPage
  } = useGetBookmarkedProjects(PROJECT_TYPE.TRAINING_INFERENCE);
  const {
    projects: evaluateBookmarkedProjects,
    isPending: isEvaluateBookmarkedProjectsLoading,
    fetchNextPage: fetchNextBookmarkedEvaluatePage,
    hasNextPage: hasEvaluateNextBookmarkedPage,
    isFetching: isFetchingNextBookmarkedEvaluatePage
  } = useGetBookmarkedProjects(PROJECT_TYPE.PROMPT_EVALUATION);
  const {
    projects: observeBookmarkedProjects,
    isPending: isObserveBookmarkedProjectsLoading,
    fetchNextPage: fetchNextBookmarkedObservePage,
    hasNextPage: hasObserveNextBookmarkedPage,
    isFetching: isFetchingNextBookmarkedObservePage
  } = useGetBookmarkedProjects(PROJECT_TYPE.OBSERVE);

  const { data: myRuns, isFetching: isMyRunsLoading } = useMyRuns();

  // Permissions
  const { getGenericPermissions } = useGenericPermissions();
  const { generic_create } = getGenericPermissions(
    currentUser?.generic_permissions || [],
    'project'
  );

  // Computed
  const isTrainInfMenu = isProjectMenuOpen === PROJECT_TYPE.TRAINING_INFERENCE;
  const isEvaluateMenu = isProjectMenuOpen === PROJECT_TYPE.PROMPT_EVALUATION;
  const isObserveMenu = isProjectMenuOpen === PROJECT_TYPE.OBSERVE;

  const renderedProjects: {
    bookmarkedProjects?: Project[];
    projects?: Project[];
  } = {
    bookmarkedProjects: [],
    projects: []
  };

  if (isTrainInfMenu) {
    renderedProjects.bookmarkedProjects =
      (NlpBookmarkedProjects as Project[]) || [];
    renderedProjects.projects = (NlpProjects as Project[]) || [];
  }

  if (isEvaluateMenu) {
    renderedProjects.bookmarkedProjects =
      (evaluateBookmarkedProjects as Project[]) || [];
    renderedProjects.projects = (evaluateProjects as Project[]) || [];
  }

  if (isObserveMenu) {
    renderedProjects.bookmarkedProjects =
      (observeBookmarkedProjects as Project[]) || [];
    renderedProjects.projects = (observeProjects as Project[]) || [];
  }

  if (activeTab === TABS.MY_RUNS) {
    renderedProjects.projects =
      myRuns?.filter((run) => run.type === 'training_inference') || [];
    renderedProjects.bookmarkedProjects = [];
  }

  const isLoading = () => {
    if (isTrainInfMenu) {
      return (
        isMyRunsLoading ||
        isNlpProjectsLoading ||
        isNlpBookmarkedProjectsLoading
      );
    }

    if (isEvaluateMenu) {
      return isEvaluateProjectsLoading || isEvaluateBookmarkedProjectsLoading;
    }

    if (isObserveMenu) {
      return isObserveProjectsLoading || isObserveBookmarkedProjectsLoading;
    }

    return false;
  };

  const isEmpty = !isLoading && !renderedProjects?.projects?.length;

  const isFetching =
    isFetchingNextNlpPage ||
    isFetchingNextEvaluatePage ||
    isFetchingNextObservePage ||
    isFetchingNextBookmarkedNlpPage ||
    isFetchingNextBookmarkedEvaluatePage ||
    isFetchingNextBookmarkedObservePage;

  const isSortingOrFiltering = Boolean(
    searchTerm ||
      filterProps?.length ||
      sortProps?.name !== 'created_at' ||
      sortProps?.ascending !== false
  );

  // Handlers
  const expandProject = (id: string) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  const handleFetchNextPage = () => {
    if (isTrainInfMenu) {
      hasNlpNextPage && fetchNextNlpPage();
    }

    if (isEvaluateMenu) {
      hasEvaluateNextPage && fetchNextEvaluatePage();
    }

    if (isObserveMenu) {
      hasObserveNextPage && fetchNextObservePage();
    }
  };

  const handleFetchNextBookmarkedPage = () => {
    if (isTrainInfMenu) {
      hasNlpNextBookmarkedPage && fetchNextBookmarkedNlpPage();
    }

    if (isEvaluateMenu) {
      hasEvaluateNextBookmarkedPage && fetchNextBookmarkedEvaluatePage();
    }

    if (isObserveMenu) {
      hasObserveNextBookmarkedPage && fetchNextBookmarkedObservePage();
    }
  };

  const handleSearchTerm = (value: string) => {
    if (value === '' || value == null) {
      return debouncedSetFilterProps(
        filterProps?.filter((f) => f.name !== 'name')
      );
    }

    setSearchTerm(value);

    const searchTermFilter: IProjectNameFilter = {
      name: 'name',
      value,
      operator: 'contains'
    };
    return debouncedSetFilterProps([searchTermFilter]);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'p' && e.metaKey && e.shiftKey) {
      setIsProjectMenuOpen(false);
    }
  };

  const onNewProjectClick = async () => {
    router.push({ pathname: Paths.CREATE_NEW_PROJECT });

    setIsProjectMenuOpen(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      searchRef?.current?.focus();
    }, 250);

    return () => clearTimeout(timer);
  }, [isProjectMenuOpen]);

  return (
    <>
      <Drawer
        isOpen={Boolean(isProjectMenuOpen)}
        title={
          <Group justify='space-between'>
            <Group pos='relative'>
              <Title order={4} size={20}>
                {projectType.label}
              </Title>
              {generic_create.allowed && (
                <LinkButton
                  buttonProps={{
                    className: classes.newProjectButton,
                    color: 'brand.8',
                    leftSection: <IconPlus height={16} width={16} />,
                    loading: isCurrentUserLoading,
                    size: 'compact-sm',
                    variant: 'subtle',
                    pos: 'relative',
                    style: { zIndex: Z_INDEX.TOOLTIPS }
                  }}
                  href={Paths.CREATE_NEW_PROJECT}
                  permissions={generic_create}
                  onClick={onNewProjectClick}
                >
                  New Project
                </LinkButton>
              )}
              {isFetching && (
                <Loader
                  data-testid='project-menu-loader'
                  size='xs'
                  type='oval'
                />
              )}
            </Group>
            <ActionIcon
              variant='subtle'
              onClick={() => setIsProjectMenuOpen(false)}
            >
              <IconX size={16} />
            </ActionIcon>
          </Group>
        }
        withCloseButton={false}
        onClose={() => setIsProjectMenuOpen(false)}
      >
        <Box pb='xs'>
          <LiveSearchBar
            autoFocus
            debounceTime={100}
            mb='xs'
            placeholder='Search by project or run name'
            ref={searchRef}
            size='sm'
            onChange={handleSearchTerm}
            onKeyDown={handleKeyDown}
          />
          <ProjectMenuFilters
            filterProps={filterProps}
            isEvaluateMenu={isEvaluateMenu}
            setFilterProps={setFilterProps}
            setSortProps={setSortProps}
            sortProps={sortProps}
          />

          <Tabs
            color='brand.5'
            mt={isTrainInfMenu ? 'xs' : 0}
            value={activeTab}
            onChange={(tab) => setActiveTab(tab as string)}
          >
            <Tabs.List
              style={{
                display: isTrainInfMenu ? 'flex' : 'none'
              }}
            >
              <Tabs.Tab value={TABS.MY_RUNS}>My Runs</Tabs.Tab>
              <Tabs.Tab value={TABS.ALL_RUNS}>All Runs</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel pt='xs' value={TABS.MY_RUNS}>
              {isEmpty ? (
                <ProjectMenuEmptyState
                  activeMenu={isProjectMenuOpen || ('unknown' as ProjectType)}
                  permissions={generic_create}
                />
              ) : (
                <ProjectMenuAccordion
                  bookmarkedProjects={
                    (renderedProjects.bookmarkedProjects as Project[]) || []
                  }
                  expandedRows={expandedRows as string[]}
                  expandProject={expandProject}
                  isLoading={isLoading()}
                  isSortingOrFiltering={isSortingOrFiltering}
                  isTrainInfMenu={isTrainInfMenu}
                  projects={(renderedProjects.projects as Project[]) || []}
                  onFetchNextBookmarkedPage={handleFetchNextBookmarkedPage}
                  onFetchNextPage={handleFetchNextPage}
                />
              )}
            </Tabs.Panel>

            <Tabs.Panel pt='xs' value={TABS.ALL_RUNS}>
              {isEmpty ? (
                <ProjectMenuEmptyState
                  activeMenu={isProjectMenuOpen || ('unknown' as ProjectType)}
                  permissions={generic_create}
                />
              ) : (
                <ProjectMenuAccordion
                  bookmarkedProjects={
                    (renderedProjects.bookmarkedProjects as Project[]) || []
                  }
                  expandedRows={expandedRows as string[]}
                  expandProject={expandProject}
                  isLoading={isLoading()}
                  isSortingOrFiltering={isSortingOrFiltering}
                  isTrainInfMenu={isTrainInfMenu}
                  projects={(renderedProjects.projects as Project[]) || []}
                  onFetchNextBookmarkedPage={handleFetchNextBookmarkedPage}
                  onFetchNextPage={handleFetchNextPage}
                />
              )}
            </Tabs.Panel>
          </Tabs>
        </Box>
      </Drawer>
    </>
  );
};

export default ProjectMenu;
