import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { USERS_ALL_PATH } from '@/core/constants/api.constants';
import { components } from '@/core/types/api';

export type User = components['schemas']['GetUserResponse'];

export const useUsers = () => {
  const fetchUsers = async () => {
    const res = await api.GET(USERS_ALL_PATH);

    return res?.data;
  };

  const response = useQuery({
    queryKey: [USERS_ALL_PATH],
    queryFn: fetchUsers
  });

  return response;
};
