import { useQuery } from '@tanstack/react-query';

const GET_UI_VERSION_PATH = '/api/version';

export const useGetUIVersion = () => {
  // Query
  const queryKey = [GET_UI_VERSION_PATH];
  const queryFn = async () => {
    const response = await fetch(GET_UI_VERSION_PATH);
    const jsonResponse = await response.json();
    return jsonResponse?.version;
  };

  return useQuery({
    queryKey,
    queryFn
  });
};
