import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';
import { PROJECT_TYPE, ProjectType } from '@/core/types/projects.types';

export const GET_LATEST_RUN = '/users/{user_id}/runs/latest';

export const useLatestRuns = ({
  type,
  userId
}: {
  type?: ProjectType;
  userId: string | undefined;
}) => {
  // Feature Flags
  const { prompt, observe } = useFeatureFlags();

  const fetchLatestRun = async () => {
    const res = await api.GET(GET_LATEST_RUN, {
      params: {
        path: { user_id: userId! },
        query: { project_type: type, limit: 15 }
      }
    });

    return res.data;
  };

  let enabled = Boolean(userId);

  if (type === PROJECT_TYPE.OBSERVE && !observe.isEnabled) {
    enabled = false;
  }

  if (type === PROJECT_TYPE.PROMPT_EVALUATION && !prompt.isEnabled) {
    enabled = false;
  }

  const response = useQuery({
    queryKey: [GET_LATEST_RUN, { userId, type }],
    queryFn: fetchLatestRun,
    enabled
  });

  return response;
};
